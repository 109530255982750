<template>
  <section>
    <div class="scanner-qr" ref="scannerQr">

      <video ref="video" class="scanner-qr-video"></video>

      <canvas ref="scannerCanvas" id="scanner-canvas" class="scanner-qr-canvas"></canvas>

      <div class="scanner-qr-ramka" ref="scannerRamka">
        <div ref="videoRamka" class="scanner-qr-video-border">
          <font-awesome-icon icon="chevron-up" class="icon alt scanner-qr-video-border-injection injection-left-top"/>
          <font-awesome-icon icon="chevron-up" class="icon alt scanner-qr-video-border-injection injection-right-top"/>
          <font-awesome-icon icon="chevron-up" class="icon alt scanner-qr-video-border-injection injection-left-bottom"/>
          <font-awesome-icon icon="chevron-up" class="icon alt scanner-qr-video-border-injection injection-right-bottom"/>
        </div>
      </div>

      <div class="scanner-qr-interface-header">
        <div class="row">
          <div class="col-6">
            <router-link class="scanner-qr-interface-btn scanner-qr-interface-btn-close" to="/sale/add/">
              <font-awesome-icon icon="times" class="icon alt"/>
            </router-link>
          </div>
           <div class="col-6 text-right">
             <a href="javascript:void(0);"
              @click="toggleFlash"
              :disabled="isFlash"
              class="scanner-qr-interface-btn scanner-qr-interface-btn-flash">
               <span v-if="!isFlashState" class="material-icons">flash_off</span>
               <span v-else class="material-icons">flash_on</span>
             </a>
          </div>
        </div>
      </div>

      <div class="scanner-qr-interface-alert">
        {{result}}
      </div>

      <div class="scanner-qr-interface-footer">
        <div class="row align-items-center h-100">
          <div class="col-6">
            <a href="javascript:void(0);" @click="this.$refs.scannerGalleryFile.click();" class="scanner-qr-interface-btn scanner-qr-interface-btn-gallery">
               <span class="material-icons">collections</span>
            </a>
            <input type="file" ref="scannerGalleryFile" @change="changeGalleryFile" class="scanner-qr-interface-btn-file">
          </div>
           <div class="col-6 text-right">
            <a href="javascript:void(0);" @click="changeCamera" class="scanner-qr-interface-btn scanner-qr-interface-btn-camera">
               <span class="material-icons">cameraswitch</span>
             </a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import QrScanner from "qr-scanner";

import QrScannerWorkerPath from '!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
  name: "ScannerQr",
  data() {
    return {
      result: "Наведите камеру на QR-код товара",
      scanner: undefined,
      listCameras: [],
      camera: "",
      isFlash: false,
      isFlashState: false,
    };
  },
  mounted() {
    this.scanner = new QrScanner(
      this.$refs.video,
      this.setResult,
      this.setError,
      this.setSize
    );
    this.scanner.start().then(() => {
      this.setListCameras();
      this.setFlash();
    });
  },
  methods: {
    setResult(result) {
      this.result = result;

      let regexp = new RegExp(process.env.VUE_APP_LINK_DIGITAL_CODE + '/' + process.env.VUE_APP_DIGITAL_CODE);

      if (regexp.test(result))
      {
        this.result = result.replace(process.env.VUE_APP_LINK_DIGITAL_CODE + '/', "");

        this.$store.dispatch("checkQr", this.result);

        this.$router.push('/sale/add');
      }

      this.scanner.stop();
    },
    setError(error) {
      console.log(error);
    },
    setSize(video) {

      // Default scan region calculation. Note that this can be overwritten in the constructor.
      const smallestDimension = Math.min(video.videoWidth, video.videoHeight);
      const scanRegionSize = Math.round((2 / 3) * smallestDimension);

      if (scanRegionSize > 0)
      {
        const sizeBlock = scanRegionSize / 2;

        this.$refs.scannerRamka.style.width =  video.videoWidth + "px";
        this.$refs.scannerRamka.style.height = video.videoHeight + "px";

        this.$refs.videoRamka.style.width =  sizeBlock + "px";
        this.$refs.videoRamka.style.height = sizeBlock + "px";

        var canv = this.$refs.scannerCanvas;

        var ctx = canv.getContext('2d');

        canv.width = video.videoWidth;
        canv.height = video.videoHeight;

        ctx.fillStyle = "rgb(255 255 255 / 80%)";

        ctx.fillRect(0, 0, video.videoWidth,video.videoHeight);

        var x = (video.videoWidth - sizeBlock) / 2;
        var y = (video.videoHeight - sizeBlock) / 2;

        ctx.clearRect(x, y, sizeBlock, sizeBlock);

      }

      return {
        x: Math.round((video.videoWidth - scanRegionSize) / 2),
        y: Math.round((video.videoHeight - scanRegionSize) / 2),
        width: scanRegionSize,
        height: scanRegionSize,
        downScaledWidth: QrScanner.DEFAULT_CANVAS_SIZE,
        downScaledHeight: QrScanner.DEFAULT_CANVAS_SIZE,
      };
    },
    setListCameras() {
      QrScanner.listCameras().then((cameras) => this.listCameras = cameras);
    },
    setSelectCamera(value) {
      this.scanner.setCamera(value).then(() => {
        this.camera = value;
        this.setFlash();
      })
    },
    setRegion(e) {
      const input = e.target;
      const label = input.parentNode;
      label.parentNode.insertBefore(this.scanner.$canvas, label.nextSibling);
      this.scanner.$canvas.style.display = input.checked ? "block" : "none";
    },
    setFlash() {
      this.scanner.hasFlash().then(hasFlash => {
          this.isFlash = hasFlash;
      });
    },
    toggleFlash() {
      this.scanner.toggleFlash().then(() => {
          this.isFlashState = this.scanner.isFlashOn() ? true : false;
      });
    },
    changeGalleryFile() {
      const file = this.$refs.scannerGalleryFile.files[0];
        if (!file) {
            return;
        }
        QrScanner.scanImage(file)
            .then(result => this.setResult(result))
            .catch(e => this.setResult(e || 'No QR code found.'));
    },
    changeCamera() {

      if (this.listCameras.length > 0)
      {
        //0 | 1
        var pos = this.listCameras.map(function(e) { return e.id; }).indexOf(this.camera);
        var newId;

        //1 < 2 | 2 < 2
        if (pos + 1 < this.listCameras.length)
        {
          newId = this.listCameras[pos + 1].id;
        }
        else
        {
          newId = this.listCameras[0].id;
        }

        this.setSelectCamera(newId);

      }
    },
  },
};
</script>

<style lang="scss" >

.scanner-qr {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #171a1f;

  &-canvas, &-video, &-ramka{
    position: fixed;
    display: block;
    left: 50%;
    top: 50%;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    z-index: 100;
    transform: translate(-50%, -50%);
  }

  &-ramka{
    width: 100px;
    height: 100px;
  }

  &-video {
    // scaleX(-1)
    transform: translate(-50%, -50%) !important;

    &-border {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-injection {
        position: absolute;
        color: #fff;
        font-size: 4em;
      }

      .injection {
        &-left-top {
          transform: rotate(-45deg);
          top: -20px;
          left: -17px;
        }
        &-right-top {
          transform: rotate(45deg);
          top: -20px;
          right: -16px;
        }
        &-left-bottom {
          transform: rotate(225deg);
          bottom: -21px;
          left: -17px;
        }
        &-right-bottom {
          transform: rotate(135deg);
          bottom: -22px;
          right: -16px;
        }
      }

    }

  }

  &-interface {

    &-header, &-footer, &-alert{
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      background-color: #171A1F;
      z-index: 2000;
    }

    &-header {
      height: 51px;
      top: 0;
    }
    &-footer {
      height: 88px;
      bottom: 0;
    }
    &-alert {
      height: 36px;
      background-color: #2940D3;
      top: 81px;
      width: 240px;
      left: 50%;
      font-size: 12px;
      color: #fff;
      text-align: center;
      line-height: 36px;
      border-radius: 5px;
      right: auto;
      transform: translateX(-50%);
      padding: 0 5px;
    }

    &-camera {
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 1;
      background-color: #fff;
      border-radius: 8px;

      & > .selector {
        margin-top: 0 !important;
      }
    }

    &-result {
      position: fixed;
      bottom: 20px;
      left: 20px;
      z-index: 1;
      color: #fff;
    }

    &-btn {

      display: inline-block;
      padding: 0px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      margin: 10px;
      color: #fff;

      &-close {
        background-color: #71747A;
        border-radius: 30px;
      }

      &-flash {
        color: #fff;
      }

      &-file {
        visibility: hidden;
        opacity: 0;
        height: 0;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: -50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
